<script>
import Vue from 'vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			slider: {},
			category: {}
		}
	},
	computed: {
	},
	async mounted() {
		this.$set(this.$root, 'page', this)
		this.getSlider()
		this.getCategory()
	},
	methods: {
		getSlider() {
			Gen.apirest("/product-slider", {}, (err, resp) => {
				if (err) console.log(err)
				this.slider = resp.data
			setTimeout(()=>{
					global.SEMICOLON.initialize.fullScreen()
					Gen.loadScript("/frontend/js/custom.js", false)
					$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
					global.SEMICOLON.slider.init();
				}, 100)
			})
		},
		getCategory() {
			Gen.apirest("/product-list-category", {}, (err, resp) => {
				if (err) console.log(err)
				this.category = resp.data
			})
		}
	},
};
</script>
<template>
	<div>
		<section id="slider" class="slider-element slider-parallax swiper_wrapper force-full-screen full-screen clearfix"
		data-loop="true" data-autoplay="5000">
			<div class="swiper-container swiper-parent">
				<div class="swiper-wrapper" >
					<a v-for="(v,k) in slider" data-aos="zoom-out" class="swiper-slide dark" :style="'background-image: url('+uploader(isMobile || isTablet?v.aph_img_mobile:v.aph_img)+');'" :href="v.aph_link" target="_blank">
						<div class="container clearfix"></div>
					</a>
				</div>
				<div class="swiper-pagination"></div>
			</div>
		</section>

		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nomargin banner-listing">
					<div class="container">
						<div class="banner products" v-for="(v,k) in category">
							<div class="ban-bg" data-aos="zoom-out" :style="'background-image: url('+uploader(v.mpc_hero_back_image)+')'"></div>
							<div class="row">
								<div class="col-lg-6">
									<div class="banner-text">
										<h2>{{ v.mpc_name }}</h2>
										<p v-html="v.mpc_desc"></p>
										<router-link :to="{name:'ProductsList', params:{slug:v.mpc_slug}}" class="box-btn">
											{{web.lbl_lihat_produk}}
										</router-link>
									</div>
								</div>
								<div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
									<img :src="uploader(v.mpc_hero_image)" :alt="v.mpc_name" :title="v.mpc_name">
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</section>
	</div>
</template>